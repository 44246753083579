<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        @created="created($event)"
        @updated="updated($event)"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <form-panel>
            <form-two-col-row>
                <template #col1>
                    <text-input
                        v-model="form.name"
                        v-validate="'required|max:255|space'"
                        name="name"
                        label="labels.name"
                        :error-messages="errors.collect('name')"
                        :data-vv-as="$t('labels.name')"
                    ></text-input>
                    <textarea-input
                        v-model="form.description"
                        v-validate="'required|space'"
                        name="description"
                        label="labels.description"
                        :error-messages="errors.collect('description')"
                        :data-vv-as="$t('labels.description')"
                    ></textarea-input>
                </template>
                <template #col2>
                    <image-editor
                        v-model="form"
                        :primary-size="{ width: 240, height: 240 }"
                        primary-key="logo"
                        primary-label="labels.logo"
                        :quality="0.9"
                    ></image-editor>
                </template>
            </form-two-col-row>
            <form-one-col-row>
                <v-input
                    v-model="form.tags"
                    v-validate="'required'"
                    name="tags"
                    :error-messages="errors.collect('tags')"
                    :data-vv-as="$t('labels.tags')"
                >
                    <v-text-field
                        v-model="newTag"
                        :label="$t('labels.add_tag')"
                        append-outer-icon="add"
                        @keyup.enter="addTag"
                        @click:append-outer="addTag"
                    ></v-text-field>
                </v-input>
                <div class="tags">
                    <v-chip
                        v-for="tag in form.tags"
                        :key="tag"
                        class="mr-1 my-1"
                        close
                        @click:close="removeTag(tag)"
                    >
                        {{ tag }}
                    </v-chip>
                </div>
            </form-one-col-row>
        </form-panel>
    </base-form>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormBaseMixin from '@/components/mixins/FormBase'
import FormPanel from '@/components/form/FormPanel.vue'
import FormOneColRow from '@/components/form/FormOneColRow.vue'
import FormTwoColRow from '@/components/form/FormTwoColRow.vue'
import TextInput from '@/components/form/Inputs/TextInput.vue'
import TextareaInput from '@/components/form/Inputs/TextareaInput.vue'
import ImageEditor from '@/components/pages/meals/ImageEditor.vue'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        TextareaInput,
        ImageEditor,
    },
    mixins: [FormBaseMixin],
    data: () => ({
        api: RestApiType.BRANDS,
        mapper: MapperType.BRAND_FORM,
        form: {
            name: null,
            logo: null,
            description: null,
            tags: [],
        },
        newTag: '',
        editRoute: routeType.BRAND_EDIT,
        listRoute: routeType.BRAND_LIST,
    }),
    methods: {
        addTag() {
            if (this.newTag) {
                this.form.tags.push(this.newTag)
                this.newTag = ''
            }
        },
        removeTag(tag) {
            this.form.tags = this.form.tags.filter((t) => t !== tag)
        },
    },
}
</script>
