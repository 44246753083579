<template>
    <brand-form :entity-id="id"></brand-form>
</template>

<script>
import DirtyFormMixin from '@/mixins/DirtyForm'
import BrandForm from './parts/BrandForm.vue'

export default {
    components: { BrandForm },
    mixins: [DirtyFormMixin],
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
