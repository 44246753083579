<template>
    <brand-form></brand-form>
</template>

<script>
import DirtyFormMixin from '@/mixins/DirtyForm'
import BrandForm from './parts/BrandForm.vue'

export default {
    components: { BrandForm },
    mixins: [DirtyFormMixin],
}
</script>
